import {BuildingOrderRequest} from "@/services/Request/BuildingOrderRequest";
import {BuildingConceptRequest} from "@/services/Request/BuildingConceptRequest";
import {ActionContext} from "vuex";
import {State} from "@/store";
import {FileRequest} from "@/services/Request/FileRequest";
import {AxiosResponse} from "axios";
import {CloseOrderRequest} from "@/services/Request/CloseOrderRequest";
import httpStatus from "http-status";
import {downloadFile} from "@/utils/FileDownloader";
import BuildingMaintenanceApi from "@/services/BuildingMaintenanceApi";
import {BuildingPlanningRequest} from "@/services/Request/BuildingPlanningRequest";

export interface BuildingOrdersState {
  buildingOrders: Array<BuildingOrderRequest>,
  buildingConcepts: Array<BuildingConceptRequest>
}

type Context = ActionContext<BuildingOrdersState, State>

export default {
  namespaced: true,
  state: (): BuildingOrdersState => ({
    buildingOrders: Array<BuildingOrderRequest>(),
    buildingConcepts: Array<BuildingConceptRequest>()
  }),

  mutations: {
    loadBuildingOrders: (state: BuildingOrdersState, orders: Array<BuildingOrderRequest>): void => {
      state.buildingOrders = orders;
    },

    loadBuildingConcepts: (state: BuildingOrdersState, concepts: Array<BuildingConceptRequest>): void => {
      state.buildingConcepts = concepts;
    },

    addBuildingConcept: (state: BuildingOrdersState, concept: BuildingConceptRequest): void => {
      let index = state.buildingConcepts.findIndex((element) => {
        return element.id === concept.id
      });

      if (index === -1) {
        state.buildingConcepts.push(concept);
      } else {
        Object.assign(state.buildingConcepts[index], concept);
      }
    },

    removeBuildingConcept: (state: BuildingOrdersState, concept: BuildingConceptRequest): void => {
      let index = state.buildingConcepts.findIndex((element) => {
        return element.id === concept.id
      });

      state.buildingConcepts.splice(index, 1)
    },

    addBuildingOrder: (state: BuildingOrdersState, order: BuildingOrderRequest): void => {
      state.buildingOrders.push(order);
    },

    updateBuildingOrder: (state: BuildingOrdersState, order: BuildingOrderRequest): void => {
      let index = state.buildingOrders.findIndex((element) => {
        return element.id === order.id
      });

      Object.assign(state.buildingOrders[index], order);
    },

    deleteBuildingOrder: (state: BuildingOrdersState, data: { orderIds: Array<string>, deleteReason: string }): void => {
      data.orderIds.forEach(id => {
        let index = state.buildingOrders.findIndex((order) => {
          return order.id === id
        })
        state.buildingOrders.splice(index, 1)
      })
    },

    addBuildingOrderFile(state: BuildingOrdersState, data:  { editedIndex: number, order: BuildingOrderRequest  }): void {
      // @ts-ignore
      data.order.transferredFiles.forEach(transferredFile => {
        const file: FileRequest = {
          id: transferredFile.id,
          name: {
            value: transferredFile.name
          }
        }
        data.order.files.push(file);
      })
    },
  },

  actions: {

    async saveBuildingSchedule(
      context: Context,
      data: {plannings: Array<BuildingPlanningRequest>, from: Date, to: Date}
    ): Promise<AxiosResponse> {
      return await BuildingMaintenanceApi.saveBuildingSchedule(data);
    },

    async closeBuildingOrder(context: Context, order: BuildingOrderRequest): Promise<AxiosResponse> {

      let data: CloseOrderRequest = {
        ids: [order.id],
        completedBy: order.completedBy,
        startDateTime: order.startDateTime,
        endDateTime: order.endDateTime,
        orderState: order.orderState,
        observations: order.observations,
        description: order.description
      }

      const response = await BuildingMaintenanceApi.closeBuildingOrder(data);

      if (response.status === httpStatus.OK) {
        context.commit('updateBuildingOrder', order)
      }

      return response;
    },

    async uploadBuildingClosedOrderFiles(context: Context, data: {editedIndex: number, item: BuildingOrderRequest}): Promise<AxiosResponse>{
      const response = await BuildingMaintenanceApi.uploadBuildingClosedOrderFiles(data.item)
      if (response.status === httpStatus.CREATED) {
        context.commit('addBuildingOrderFile', {
          editedIndex: data.editedIndex, order: data.item
        })
      }
      return response
    },
    async getBuildingClosedOrderFile(context: Context, filename: string): Promise<void> {
      const response = await BuildingMaintenanceApi.getBuildingClosedOrderFile(filename);
      if (response.status === httpStatus.OK) {
        downloadFile(response.data, filename)
      }
    },
    async deleteBuildingOrderFile(context: Context, fileId: string): Promise<AxiosResponse> {
      return await BuildingMaintenanceApi.deleteBuildingOrderFile(fileId)
    },

    async getBuildingConcepts(context: Context): Promise<void> {
      const response = await BuildingMaintenanceApi.getBuildingConcepts();
      if (response.status === httpStatus.OK) {
        context.commit('loadBuildingConcepts', response.data)
      }
    },

    async saveBuildingConcept(context: Context, concept: BuildingConceptRequest): Promise<AxiosResponse> {
      const response = await BuildingMaintenanceApi.saveBuildingConcept(concept);
      if (response.status === httpStatus.CREATED) {
        context.commit('addBuildingConcept', concept)
      }

      return response
    },

    async deleteBuildingConcept(context: Context, concept: BuildingConceptRequest): Promise<AxiosResponse> {
      const response = await BuildingMaintenanceApi.deleteBuildingConcept(concept.id);
      if (response.status === httpStatus.OK) {
        context.commit('removeBuildingConcept', concept)
      }

      return response
    },

    async saveBuildingMaintenancePlanning(context: Context, planning: BuildingPlanningRequest) {
      return await BuildingMaintenanceApi.saveBuildingMaintenancePlanning(planning)
    },

    async deleteBuildingMaintenancePlanning(context: Context, planningId: string) {
      return await BuildingMaintenanceApi.deleteBuildingMaintenancePlanning(planningId)
    },

    async getBuildingOrders(context: Context, period: {from: string, to: string}): Promise<void> {
      const response = await BuildingMaintenanceApi.getBuildingOrders(period);
      if (response.status === httpStatus.OK) {
        context.commit('loadBuildingOrders', response.data)
      }
    },

    async getBuildingOrder(context: Context, id: string): Promise<void> {
      const response = await BuildingMaintenanceApi.getBuildingOrder(id)
      return response.data
    },

    async saveBuildingOrder(context: Context, data: { editedIndex: number, item: BuildingOrderRequest }): Promise<AxiosResponse> {
      const response = await BuildingMaintenanceApi.saveBuildingOrder(data.item);

      if (response.status === 201) {
        let newOrder = JSON.parse(JSON.stringify(data.item));
        newOrder.number.value = response.data.number.value
        newOrder.planning = response.data.planning ?? ''
        newOrder.sector = response.data.sector

        if (data.editedIndex === -1) {
          context.commit('addBuildingOrder', newOrder);
        } else {
          context.commit('updateBuildingOrder', newOrder);
        }
      }

      return response;
    },

    async deleteBuildingOrder(context: Context, data: { orderIds: Array<string>, deleteReason: string }): Promise<AxiosResponse> {
      const response = await BuildingMaintenanceApi.deleteBuildingOrder(data);

      if (response.status === 200) {
        context.commit('deleteBuildingOrder', data)
      }

      return response;
    },

    async getReportBuildingOrder(context: Context, id: string): Promise<AxiosResponse> {
      const response = await BuildingMaintenanceApi.getBuildingReportOrder(id);
      if (response.status === 200) {
        let blob = new Blob([response.data], {type: 'application/pdf'})
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      }

      return response;
    },
    async getFilteredBuildingOrdersReport(context: Context, orderIds: Array<string>): Promise<void> {
      const response = await BuildingMaintenanceApi.getFilteredBuildingOrdersReport(orderIds);
      if (response.status === 200) {
        let blob = new Blob([response.data], {type: 'application/pdf'})
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      }
    }
  },

  getters: {
    getBuildingOrders: (state: BuildingOrdersState): Array<BuildingOrderRequest> => {
      return state.buildingOrders;
    },
    getBuildingConcepts: (state: BuildingOrdersState): Array<BuildingConceptRequest> => {
      return state.buildingConcepts;
    },
  }
}