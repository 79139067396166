<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-row class="justify-center">
          <h1>Planificación Mantenimiento Máquinas</h1>
        </v-row>
        <v-row class="justify-center mt-7">
          <p>Este proceso realiza una programación de los conceptos de mantenimiento
            generando órdenes de trabajo para cada uno de ellos en función del período
            programado y de la frecuencia de cada concepto.
          </p>
          <p>
            Serán borradas todas las órdenes que ya se encuentran planificadas en el mismo período.
          </p>
        </v-row>
        <v-form ref="scheduleForm">
          <v-row class="justify-center">
            <v-col md="3">
              <v-radio-group
                v-model="maintenanceType"
                mandatory row
                label="Tipo Mant."
                @change="setMaintenanceType"
              >
                <v-radio
                  :value="PREVENTIVE_ORDER()"
                  label="Preventiva"
                />
                <v-radio
                  :value="AUTONOMOUS_ORDER()"
                  label="Autónomo"
                />
              </v-radio-group>
            </v-col>
            <v-col md="2">
              <FormDate
                  :is-required="true" :label="labelFrom" :date="editedItem.from"
                  @on-change="onChangeDateFrom" />
            </v-col>
            <v-col md="2">
              <FormDate
                  :is-required="true" :label="labelTo" :date="editedItem.to"
                  @on-change="onChangeDateTo" />
            </v-col>
          </v-row>
        </v-form>
        <v-row class="justify-center my-3">
          <v-btn
            color="primary"
            @click="validateAndShowConfirm"
          >
            Programar
          </v-btn>
        </v-row>
      </v-container>
    </v-card>

    <v-row class="justify-center mt-6">
      <v-btn @click="refreshScheduling">
        <v-icon class="mr-1">
          mdi-refresh
        </v-icon>
        Actualizar planificación
      </v-btn>
    </v-row>
    <v-row class="mt-5">
      <v-col md="6">
        <v-text-field
          filled solo rounded single-line hide-details
          v-model="searchMachineCode"
          append-icon="mdi-magnify"
          label="Buscar Máquina"
        />

        <v-data-table
          v-model="selectedMachines"
          show-select
          @toggle-select-all="selectOrDeselectMachines"
          @item-selected="selectMachine"
          :headers="machineHeaders"
          :items="getApplicableMachines"
          item-key="id"
          class="elevation-2 mt-5"
          :page.sync="machinesPage"
          :items-per-page="5"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 30]
          }"
        >
          <template v-slot:item.schedule="{item}">
            <v-icon :color="item.seeing ? 'red darken-3' : 'primary'" @click="filterPlanningByMachine(item)">
              {{ item.seeing ? 'mdi-eye-off' : 'mdi-eye' }}
            </v-icon>
          </template>

        </v-data-table>
      </v-col>
      <v-col md="6" v-if="seeingMachinePlannings">
        <v-text-field
          filled solo rounded :clearable="!seeingMachinePlannings"
          v-model="searchPlanning"
          single-line hide-details
          :readonly="seeingMachinePlannings"
        />

        <v-data-table
          show-select
          v-model="selectedPlannings"
          :headers="planningHeaders"
          :items="plannings"
          :search="searchPlanning"
          item-key="id"
          class="elevation-2 mt-5"
          :items-per-page="-1"
          hide-default-footer
        />
      </v-col>
    </v-row>

    <SnackBar />
    <DialogConfirm
      :show-dialog="showConfirmSchedule" parent-data="¿Confirma la programación de órdenes?"
      @on-close-dialog="showConfirmSchedule = false"
      @confirm-action="saveSchedule"
    />
  </v-container>
</template>

<script>
import FormDate from "@/components/base/FormDate.vue";
import httpStatus from "http-status";
import moment from "moment";
import {mapGetters} from "vuex";
import {AUTONOMOUS_ORDER, PREVENTIVE_ORDER} from "@/types/OrderTypes";
import SnackBar from "@/components/base/Snackbar.vue";
import RefreshButton from "@/components/base/RefreshButton.vue";
import DialogConfirm from "@/components/base/DialogConfirm.vue";

export default {
  name: "ScheduleMaintenance",
  components: {DialogConfirm, RefreshButton, SnackBar, FormDate},
  data: () => {
    return {
      showConfirmSchedule: false,
      seeingMachinePlannings: false,
      maintenanceType: PREVENTIVE_ORDER,
      searchPlanning: '',
      searchMachineCode: '',
      plannings: [],
      selectedPlannings: [],
      selectedMachines: [],
      machinesPage: 1,
      planningHeaders: [
        {text: 'Descripción', value: 'concept.title'},
        {text: 'Frec. (dias)', value: 'daysFrequency'},
        {text: 'Día Inicio', value: 'startDay'},
        {text: 'Cod. Maquina', value: 'machineCode', align: ' d-none'},
      ],
      machineHeaders: [
        {text: 'Código', value: 'code.value'},
        {text: 'Descripción', value: 'description'},
        {text: 'Planif.', value: 'schedule'},
      ],
      labelFrom: 'Inicio',
      labelTo: 'Finalización',
      editedItem: '',
      defaultItem: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().add(365,'days').format('YYYY-MM-DD')
      },
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
    }
  },

  computed: {
    ...mapGetters({
      getMachines: 'machines/getMachines'
    }),
    getApplicableMachines() {
      const machines = this.getMachines.filter(machine =>
          machine.plannings?.length > 0 &&
          (machine.code.value.includes(this.searchMachineCode) || machine.description.toLowerCase().includes(this.searchMachineCode.toLowerCase()))
      )
      return this.maintenanceType === PREVENTIVE_ORDER
        ? machines.filter(machine => machine.plannings.some(planning => !planning.isAutonomous))
        : machines.filter(machine => machine.plannings.some(planning => planning.isAutonomous))
    },
  },

  async created() {
    await this.$store.dispatch('machines/getMachines');
    this.editedItem = Object.assign({}, this.defaultItem);
    this.cleanSelection()
  },

  methods: {
    AUTONOMOUS_ORDER() {
      return AUTONOMOUS_ORDER
    },
    PREVENTIVE_ORDER() {
      return PREVENTIVE_ORDER
    },
    onChangeDateFrom(date) {
      this.editedItem.from = date
    },
    onChangeDateTo(date) {
      this.editedItem.to = date
    },

    setMaintenanceType(type) {
      this.editedItem.from = moment().format('YYYY-MM-DD')

      if (type === PREVENTIVE_ORDER) {
        this.editedItem.to = moment().add(365,'days').format('YYYY-MM-DD')
      }
      if (type === AUTONOMOUS_ORDER) {
        this.editedItem.to = moment().add(30,'days').format('YYYY-MM-DD')
      }

      this.cleanSchedule()
    },

    async refreshScheduling() {
      await this.$store.dispatch('machines/getMachines')
      this.$forceUpdate()
      this.cleanSchedule()
    },

    cleanSelection() {
      const item = {
        value: false
      }
      this.selectOrDeselectMachines(item)
    },

    selectMachine(item) {
      if (item.value) {
        this.addPlannings(item.item)
      }else{
        item.item.plannings.forEach((planning)=>{
          this.removePlaning(planning)
        })
      }
      this.selectedPlannings = this.plannings
    },

    // all selected plannings must be cleaned when deselect all sectors
    selectOrDeselectMachines(item) {
      if (item.value) {
        this.selectedMachines = this.getApplicableMachines
        this.plannings = []
        this.getApplicableMachines.forEach((machine) => {
          this.addPlannings(machine)
        });
        this.selectedPlannings = this.plannings
      } else {
        this.selectedMachines = []
        this.getApplicableMachines.forEach((machine) => {
          machine.plannings.forEach((planning) => {
            this.removePlaning(planning)
          });
        });
        this.selectedPlannings = []
      }
    },

    addPlannings(machine) {
      machine.plannings.forEach(planning => {
        planning.machineCode = 'Cod ' + machine.code.value
      })
      this.plannings.push(...machine.plannings.filter(planning => {
        return this.maintenanceType === PREVENTIVE_ORDER
          ? !planning.isAutonomous
          : planning.isAutonomous
      }))
    },
    removePlaning(planning) {
      const index = this.plannings.indexOf(planning)
      if (index > -1)
        this.plannings.splice(this.plannings.indexOf(planning), 1)
    },

    filterPlanningByMachine(machine) {
      if (machine.seeing) {
        machine.seeing = false
        this.searchPlanning = ''
        this.seeingMachinePlannings = false

      } else {
        this.getApplicableMachines.forEach(machine => {
          machine.seeing = false
        })
        machine.seeing = true
        this.searchPlanning = 'Cod ' + machine.code.value
        this.seeingMachinePlannings = true
      }

      this.$forceUpdate()
    },

    validateAndShowConfirm() {
      if (!this.$refs.scheduleForm.validate()) return

      this.showConfirmSchedule = true
    },

    async saveSchedule() {
      this.showConfirmSchedule = false

      if (this.maintenanceType === AUTONOMOUS_ORDER) {
        const from = new Date(this.editedItem.from)
        const to = new Date(this.editedItem.to)
        const diff = to.getTime() - from.getTime()
        if (diff / (1000 * 60 * 60 * 24) > 31) {
          this.$store.commit('app/showSnack');
          this.$store.commit('app/setSnackColor', 'red');
          this.$store.commit('app/setSnackMsg', 'El período para mantenimiento autonomo no puede superar el mes');
          return
        }
      }

      const response = await this.$store.dispatch('orders/saveSchedule', {
        plannings: this.selectedPlannings,
        from: this.editedItem.from,
        to: this.editedItem.to,
      });

      if (response.status === httpStatus.OK) {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.setMaintenanceType(this.maintenanceType)
      }
    },

    cleanSchedule() {
      this.searchMachineCode = ''
      this.searchPlanning = ''
      this.seeingMachinePlannings = false
      this.plannings = []
      this.getApplicableMachines.forEach(machine => {
        machine.seeing = false
      })

      this.cleanSelection()
      this.$nextTick(() => {
        this.machinesPage = 1
      })
    }
  }

}
</script>

<style scoped>

</style>