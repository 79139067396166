import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/machines/Home.vue'
import Login from '../views/machines/Login.vue'
import Machines from '../views/machines/Machines.vue'
import Parts from '../views/machines/Parts.vue'
import Orders from '../views/machines/Orders.vue'
import Concepts from '../views/machines/Concepts.vue'
import store from "@/store";
import ScheduleMaintenance from '../views/machines/ScheduleMaintenance.vue';
import Sectors from "@/views/building/Sectors.vue";
import BuildingConcepts from "@/views/building/BuildingConcepts.vue";
import BuildingScheduleMaintenance from "@/views/building/BuildingScheduleMaintenance.vue";
import BuildingOrders from "@/views/building/BuildingOrders.vue";
import CloseAutonomousMaintenance from "@/views/machines/CloseAutonomousMaintenance.vue";
import PrintAutonomousMaintenance from "@/views/machines/PrintAutonomousMaintenance.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/machines',
    name: 'Machines',
    component: Machines
  },
  {
    path: '/parts',
    name: 'Parts',
    component: Parts
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/concepts',
    name: 'Concepts',
    component: Concepts
  },
  {
    path: '/close-autonomous-maintenance',
    name: 'CloseAutonomousMaintenance',
    component: CloseAutonomousMaintenance
  },
  {
    path: '/print-autonomous-maintenance',
    name: 'PrintAutonomousMaintenance',
    component: PrintAutonomousMaintenance
  },
  {
    path: '/schedule-maintenance',
    name: 'ScheduleMaintenance',
    component: ScheduleMaintenance
  },

  {
    path: '/sectors',
    name: 'Sectors',
    component: Sectors
  },
  {
    path: '/building-concepts',
    name: 'BuildingConcepts',
    component: BuildingConcepts
  },
  {
    path: '/building-orders',
    name: 'BuildingOrders',
    component: BuildingOrders
  },
  {
    path: '/building-schedule-maintenance',
    name: 'BuildingScheduleMaintenance',
    component: BuildingScheduleMaintenance
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{

  // const isAuthenticated: boolean = store.getters['app/isAuthenticated'];


  // if (to.name !== 'Login' && !isAuthenticated) {
  //   next({name: 'Login'});
  // } else {
  //   next();
  // }

  next();
})

export default router
